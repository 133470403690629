<template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Name"
                :label="$t('sysUser.name', locale)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <color-picker-primary-secondary
                style="width: 100px;"
                :primaryColor="editedItem.PrimaryColor"
                :secondaryColor="editedItem.SecondaryColor"
                :onChangeColor="handleChangeColors"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.RenameFolders"
                :label="$t('workspace.renameFolders', locale)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >12
              <select-sys-users
                area="dfiles"
                :workspaceID="editedItem.ID"
                :onChangeUser="handleChangeUser"
              />
                ---
              <select-sys-users-permissions
                area="dfiles"
                :workspaceID="editedItem.ID"
                :onChangeUser="handleChangeUser"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <action-buttons
        :id="editedItem.ID"
        :onDeleteItem="handleDeleteItem"
        :onSaveItem="handleSave"
        :onClose="handleClose"
        :locale="locale"
      />
    </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import SelectSysUsers from '@/components/SelectSysUsers'
  import SelectSysUsersPermissions from '@/components/SelectSysUsersPermissions'
  import ActionButtons from '@/components/ActionButtons'
  import ColorPickerPrimarySecondary from '@/components/ColorPickerPrimarySecondary'

  export default {
    components: {
      ActionButtons,
      SelectSysUsers,
      SelectSysUsersPermissions,
      ColorPickerPrimarySecondary,
    },
    props: {
      editID: {
        type: String,
        required: false,
        default: null
      },
      dialog: {
        type: Boolean,
        required: false,
        default: false
      },
      onGetData: {
        type: Function,
        required: true
      },
      onCloseDialog: {
        type: Function,
        required: true
      }
    },
    data: () => ({
      formTitle: '',
      defaultItem: {
        Name: '',
        Users: [],
        RenameFolders: null,
        PrimaryColor: null,
        SecondaryColor: null,
      },
      editedItem: {}
    }),
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      dialog (v) {
        if (v) 
          if (this.editID) this.handleGetItem (this.editID)
          else this.editedItem = this.defaultItem
      }
    },
    methods: {
      handleGetItem(v) {
        api.getItem ('dfiles', 'v1/system/workspaces/', v)
        .then(response => {   
          this.editedItem = response
        })
      },
      handleChangeUser (v) {
        this.editedItem.Users = v
      },
      handleChangeColors (primaryColor, secondaryColor) {
        this.editedItem.PrimaryColor = primaryColor ? primaryColor : null
        this.editedItem.SecondaryColor = secondaryColor ? secondaryColor : null
      },
      handleClose () {
        this.onCloseDialog()
      },
      handleDeleteItem (v) {
        if (v) 
          api.deleteItem ('dfiles', 'v1/system/workspaces/', v)
          .then(() => {                      
            this.onGetData()
            this.onCloseDialog()
          })
      },
      handleSave () {
        if (!this.editedItem.RenameFolders || this.editedItem.RenameFolders.length === 0) this.editedItem.RenameFolders = null
        if (this.editedItem.ID)
          api.updateItem ('dfiles', 'v1/system/workspaces/', this.editedItem.ID, this.editedItem)
          .then(() => {          
            this.onGetData()
            this.onCloseDialog()
          })  
        else
          api.addItem ('dfiles', 'v1/system/workspaces/', this.editedItem)
          .then(() => {          
            this.onGetData()
            this.onCloseDialog()
          })        
      }
    },
  }
</script>
